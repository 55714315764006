<template>
  <div>
    <v-container grid-list-xl fluid>
      <header-title title="Registro modalidad B"></header-title>
      <div v-if="convocatoria.id > 0">
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-text>
                <p class="subtitle-1 font-weight-bold">
                  {{ convocatoria.cite }}
                </p>
                <p>
                  El/la responsable de la ESFM/UA debe verificar los
                  <b>requisitos</b> presentados por el/la postulante en las
                  siguientes modalidades <b>B1, B2, B3, B4 y B5</b>.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mb-1">
          <v-col cols="12">
            <v-card>
              <v-card-text>
                <v-form ref="formp" @submit.prevent="getPostulantes()">
                  <v-row class="mt-1">
                    <v-col cols="12" sm="4">
                      <v-select
                        label="Modalidades"
                        v-model="options.sigla"
                        :items="modalidades"
                        item-text="sigla"
                        item-value="sigla"
                        :rules="[(v) => !!v || 'Seleccione una opción']"
                        @change="getPostulantes()"
                        hide-details
                        filled
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field
                        v-model="options.search"
                        label="CI o DNI, nombre, apellidos"
                        :rules="[(v) => !!v || '']"
                        @input="changeSearch"
                        hint="1234567 o 1234567-1G"
                        hide-details
                        clearable
                        filled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-btn
                        type="submit"
                        large
                        color="primary"
                        :loading="load_search"
                      >
                        <v-icon>mdi-magnify</v-icon>
                        Buscar
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
                <v-data-table
                  :loading="loading"
                  :headers="headers"
                  :items="postulantes"
                  :page.sync="options.page"
                  :items-per-page="options.itemsPerPage"
                  hide-default-footer
                  class="elevation-1"
                >
                  <template v-slot:[`item.num`]="{ item }">
                    <td>
                      {{
                        (options.page - 1) * options.itemsPerPage +
                        (postulantes.indexOf(item) + 1)
                      }}
                    </td>
                  </template>
                  <template v-slot:[`item.accion`]="{ item }">
                    <td>
                        
                      <!-- <v-btn
                        v-if="item.estado_id == 1"
                        small
                        color="success"
                        class="mr-1"
                        @click="
                          registerRequisito(
                            item.id,
                            item.a_persona.nombre_completo,
                            item.a_persona.cedula_dni,
                            item.a_persona.complemento,
                            item.a_persona.fecha_nacimiento,
                          )
                        "
                      >
                        Registro
                      </v-btn>
                      <v-btn v-else small class="mr-1" disabled>
                        Registro
                      </v-btn>  -->
                       <!-- <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-if="item.estado_id == 10 || item.estado_id == 9"
                            small
                            color="info"
                            class="mr-1"
                            @click="
                              editarRequisito(
                                item.id,
                                item.a_persona.nombre_completo
                              )
                            "
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon>mdi-file-edit</v-icon>
                          </v-btn>
                        </template>
                        <span>Editar</span>
                      </v-tooltip>    -->

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-if="item.estado_id == 10 || item.estado_id == 9"
                            small
                            dark
                            color="red"
                            :loading="load_send"
                            @click="
                              printFormCentralizador(
                                item.id,
                                item.modalidad.sigla
                              )
                            "
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon>mdi-printer-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Formulario</span>
                      </v-tooltip>
                    </td>
                  </template>
                </v-data-table>
                <div class="v-data-footer">
                  <v-row>
                    <v-col cols="12" sm="4">
                      <div class="v-data-footer__select">
                        Filas por página:
                        <v-select
                          :items="[10, 20, 30, 50, 100]"
                          v-model="options.itemsPerPage"
                          @change="changePerPage()"
                          hide-details
                          class="my-0"
                        ></v-select>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="8" class="text-center">
                      <v-pagination
                        v-model="options.page"
                        :length="options.pageCount"
                        :total-visible="5"
                        @input="changePage"
                      ></v-pagination>
                    </v-col>
                  </v-row>
                </div>
                <div v-if="type_state === 2">
                  <span class="red--text">
                    No hay registro con la
                    <b>Cédula Identidad o DNA</b> ingresada.
                  </span>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <notify-card
          :title="notify_title"
          :load="notify_load"
          process="el proceso de admisión"
        ></notify-card>
      </div>
    </v-container>
  </div>
</template>

<script>
import HeaderTitle from "@/components/HeaderTitle";
import NotifyCard from "@/components/NotifyCard";
import Service from "../utils/general";
import PDFService from "../utils/pdf";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { mapActions } from "vuex";
import axios from "axios";

export default {
  name: "admision-lista-postulante-b",
  components: {
    HeaderTitle,
    NotifyCard,
  },
  data: () => ({
    notify_title: "Cargando",
    notify_load: true,
    loading: false,
    load_search: false,
    load_send: false,
    type_state: 1,
    cedula_dni: "",
    convocatoria: {
      id: null,
      cite: "",
    },
    headers: [
      {
        text: "#",
        align: "start",
        sortable: false,
        value: "num",
      },
      { text: "Apellidos y nombres", value: "a_persona.nombre_completo" },
      { text: "Cédula identidad", value: "a_persona.cedula_dni" },
      { text: "Celular", value: "a_persona.celular" },
      { text: "Especialidad", value: "especialidad.descripcion" },
      { text: "Acciones", value: "accion" },
    ],
    options: {
      search: "",
      convocatoria_id: "",
      institucion_id: "",
      sigla: "",
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
    },
    modalidades: [],
    postulantes: [],
    postulante: "",
    postulante_id: "",
    user: null,
  }),
  created() {
    this.user = Service.getUser();
    if (this.user == null) {
      this.$router.replace({ name: "inicio" });
    }
  },
  mounted() {
    const roles = [8, 11];
    if (this.user && roles.includes(this.user.rid)) {
      this.options.institucion_id = this.user.ies;
      this.getModalidades();
    } else {
      this.$router.replace({ name: "inicio" });
    }
  },
  methods: {
    ...mapActions(["toast"]),
    getModalidades() {
      this.notify_load = true;
      axios
        .get(Service.getUrlAPI() + "admision/modalidad", Service.getHeader())
        .then((response) => {
          this.convocatoria = response.data.convocatoria
            ? response.data.convocatoria
            : { id: null, cite: "" };
          if (response.data.modalidades.length > 0) {
            this.modalidades = response.data.modalidades.filter(
              (item) => item.sigla != "A"
            );
            this.options.sigla = "B1";
          }
          this.options.convocatoria_id = this.convocatoria.id;
          this.notify_title = "Notificación";
          this.notify_load = false;
          this.getPostulantes();
        })
        .catch(() => {
          this.notify_load = false;
          console.error("Parametros no encontrado");
        });
    },

    getPostulantes() {
      this.loading = true;
      axios
        .post(
          Service.getUrlAPI() + "admision/postulantes/modalidadb",
          this.options,
          Service.getHeader()
        )
        .then((response) => {
          this.loading = false;
          this.postulantes = response.data.rows;
          if (response.data.count > 0) {
            this.options.pageCount =
              Math.floor(response.data.count / this.options.itemsPerPage) + 1;
          }
        })
        .catch((error) => {
          this.loading = false;
          console.error("Error al cargar registros", error);
        });
    },

    changeSearch() {
      if (this.options.search && this.options.search.length > 2) {
        this.getPostulantes();
      }
    },

    changePerPage() {
      this.options.page = 1;
      this.getPostulantes();
    },

    changePage() {
      this.getPostulantes();
    },

    async registerRequisito(id, nombre_postulante, cedula_dni, complemento, fecha_nacimiento) {
      await this.$store.dispatch("postulante", {
        id: id,
        nombre: nombre_postulante,
        modalidad: this.options.sigla,
        cedula_dni:cedula_dni,
        complemento:complemento,
        fecha_nacimiento:fecha_nacimiento,
      });
      this.$router.push({ name: "admision-registrar-requisito" });
    },

    async editarRequisito(id, nombre_postulante) {
      await this.$store.dispatch("postulante", {
        id: id,
        nombre: nombre_postulante,
        modalidad: this.options.sigla,
      });
      this.$router.push({ name: "admision-editar-requisito" });
    },

    printFormCentralizador(postulante_id, modalidad) {
      this.load_send = true;
      axios
        .post(
          Service.getUrlAPI() + "admision/formulario/centralizador",
          {
            postulante_id: postulante_id,
            convocatoria_id: this.convocatoria.id,
            institucion_id: this.options.institucion_id,
          },
          Service.getHeader()
        )
        .then(async (response) => {
          this.load_send = false;
          var bodyPdf = await PDFService.bodyCentralizadorPDF(
            response.data,
            modalidad
          );

          var docDefinition = {
            pageSize: "LETTER",
            pageMargins: [38, 70, 38, 30],
//            pageMargins: [40, 70, 40, 30],
            header: PDFService.setting().header,
            footer: PDFService.setting().footer,
            content: bodyPdf,
          };
          pdfMake
            .createPdf(docDefinition)
            .download("FormularioCentralizador.pdf");
          // pdfMake.createPdf(docDefinition).open();
        })
        .catch((err) => {
          this.load_send = false;
          console.error(err);
        });
    },
  },
};
</script>
